@font-face {
  font-family: "Klavika";
  src: url("./assets/fonts/KlavikaWebBasicMedium.woff2") format("woff");
}

@font-face {
  font-family: "Klavika Light";
  src: url("./assets/fonts/KlavikaWebBasicLight.woff2") format("woff");
}

body {
  margin: 0;
  font-family: "Klavika", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
